import Script from 'next/script'
import { publicConfig } from '../../../lib/publicConfig'
import { useNostoContext } from './NostoContext'

export function NostoScript() {
  const { loadNosto } = useNostoContext()

  if (!publicConfig.nostoAccountId) {
    return null
  }

  return (
    <>
      <Script
        id="core-nosto"
        src={`https://connect.nosto.com/include/script/${publicConfig.nostoAccountId}.js`}
        strategy="lazyOnload"
        onLoad={() => {
          loadNosto()
        }}
        onError={(e) => {
          console.error('Nosto script failed to load', e)
        }}
      />
    </>
  )
}
