/**
 * A utility function that utilizes a customer group id to determine
 * if they have orders and active/canceled autoships.
 *
 * Segment Slots: [visitorTypes]|[orders]|[activeAutoships]|[cancelledAutoships]
 * visitorTypes: guest | employee | customer
 * orders: none | attempted | single | multi
 * activeAutoships: none | single | multi
 * cancelledAutoships: none | single | multi
 *
 * @param customerGroupId - a number representing the customer's group id
 * @returns an object `{ hasActiveAutoships: boolean, hasCancelledAutoship: boolean, hasOrders: boolean }`
 */
export function getCustomerSegments(customerGroupId: number | null | undefined) {
  const CUSTOMER_GROUPS = {
    6: 'NONE_NONE_NONE', // customer|none|none|none
    7: 'ATTEMPTED_NONE_NONE', // customer|attempted|none|none
    8: 'SINGLE_NONE_NONE', // customer|single|none|none
    9: 'SINGLE_NONE_SINGLE', // customer|single|none|single
    10: 'SINGLE_NONE_MULTI', // customer|single|none|multi
    11: 'SINGLE_SINGLE_NONE', // customer|single|single|none
    12: 'SINGLE_SINGLE_SINGLE', // customer|single|single|single
    13: 'SINGLE_SINGLE_MULTI', // customer|single|single|multi
    14: 'SINGLE_MULTI_NONE', // customer|single|multi|none
    15: 'SINGLE_MULTI_SINGLE', // customer|single|multi|single
    16: 'SINGLE_MULTI_MULTI', // customer|single|multi|multi
    17: 'MULTI_NONE_NONE', // customer|multi|none|none
    18: 'MULTI_NONE_SINGLE', // customer|multi|none|single
    19: 'MULTI_NONE_MULTI', // customer|multi|none|multi
    20: 'MULTI_SINGLE_NONE', // customer|multi|single|none
    21: 'MULTI_SINGLE_SINGLE', // customer|multi|single|single
    22: 'MULTI_SINGLE_MULTI', // customer|multi|single|multi
    23: 'MULTI_MULTI_NONE', // customer|multi|multi|none
    24: 'MULTI_MULTI_SINGLE', // customer|multi|multi|single
    25: 'MULTI_MULTI_MULTI', // customer|multi|multi|multi
  }

  const customerSegment = ['SINGLE', 'MULTI']
  const [ordersValue, activeAutoshipsValue, cancelledAutoshipsValue] = (
    CUSTOMER_GROUPS[customerGroupId || 0] || ''
  ).split('_')

  return {
    hasActiveAutoships: customerSegment.includes(activeAutoshipsValue),
    hasCancelledAutoship: customerSegment.includes(cancelledAutoshipsValue),
    hasOrders: customerSegment.includes(ordersValue),
    hasMultipleActiveAutoships: activeAutoshipsValue === 'MULTI',
    hasMultipleOrders: ordersValue === 'MULTI',
  }
}
