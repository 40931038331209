import routes from '../../routes'

/**
 * An API call to the Autoship engine to update the logged in customer's
 * `customerGroupId` field in BigCommerce. Calling this end point will
 * also set a client-side cookie named `cwCustSegTTL` that will expire after
 * a short period of time.
 *
 * @remarks
 * The Autoship engine handles a customer's Autoship subscription status;
 * therefore when a customer adds/cancels an Autoship, we'll want to make
 * sure their that their `customerGroupId` in BigCommerce is up-to-date.
 *
 * @param forceRefresh - optional boolean to forcibly call the Autoship engine endpoint regardless if `document.cookie` contains a valid `cwCustSegTTL` cookie
 */
export async function updateCustomerSegments(forceRefresh?: boolean) {
  try {
    const cwCustSegTTLCookie = (
      document.cookie.match('(^|[^;]+)\\s*' + 'cwCustSegTTL' + '\\s*=\\s*([^;]+)') || []
    ).pop()

    if (cwCustSegTTLCookie && !forceRefresh) {
      return
    }

    const res = await fetch(routes.cwAPI.autoship.customers.updateAutoshipCustomerSegment(), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (!res.ok) {
      throw Error("Failed to update the customer's group segment")
    }
  } catch (err) {
    console.error(err)
  }
}
