import type { ReactNode } from 'react'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import KUSTOMER_CHAT_UNAVAILABLE_STATUSES from '../../lib/constants/KUSTOMER_CHAT_UNAVAILABLE_STATUSES'

export enum ChatStatusText {
  AVAILABLE = 'Available Now',
  LOADING = 'Loading...',
  UNAVAILABLE = 'Unavailable',
}

export const KustomerChatContext = createContext({
  initialized: false,
  statusText: ChatStatusText.LOADING,
  unavailable: true,
  openChat: () => {
    return
  },
})

export function KustomerChatProvider({ children }: { children: ReactNode }) {
  const [statusText, setStatusText] = useState<ChatStatusText>(ChatStatusText.LOADING)
  const [initialized, setInitialized] = useState(false)
  const unavailable = useMemo(() => statusText === ChatStatusText.UNAVAILABLE, [statusText])

  const openChat = useCallback(() => {
    const Kustomer = window.Kustomer
    // https://developer.kustomer.com/chat-sdk/v2.0-Web/docs/kustomer-open
    if (Kustomer) Kustomer.open()
  }, [])

  // we're initializing chat from a GTM script, so we should only be
  // able to open the chat once the script has been fully loaded
  useEffect(() => {
    const setChatStatus = () => {
      try {
        const Kustomer = window.Kustomer
        if (!Kustomer) throw Error("Chat hasn't been initialized yet")

        // https://developer.kustomer.com/chat-sdk/v2.0-Web/docs/kustomer-is-chat-available
        const { availability } = Kustomer.isChatAvailable()

        const chatIsAvailable =
          availability && !KUSTOMER_CHAT_UNAVAILABLE_STATUSES.includes(availability)

        setStatusText(chatIsAvailable ? ChatStatusText.AVAILABLE : ChatStatusText.UNAVAILABLE)
      } catch (error) {
        setStatusText(ChatStatusText.UNAVAILABLE)
      } finally {
        setInitialized(true)
      }
    }

    // this "kustomerStarted" event is a custom event we emit within our
    // GTM "Kustomer Chat - Start" script to ensure we can access the
    // Kustomer object on the window
    window.addEventListener('kustomerStarted', setChatStatus)

    return () => {
      window.removeEventListener('kustomerStarted', setChatStatus)
    }
  }, [])

  return (
    <KustomerChatContext.Provider value={{ initialized, openChat, statusText, unavailable }}>
      {children}
    </KustomerChatContext.Provider>
  )
}

export function useKustomerChatStatus() {
  return useContext(KustomerChatContext)
}
