const routes = {
  homePage: () => '/',
  product: (slug: string) => `/product/${slug}`,
  bigCommerceAccount: {
    index: () => `/account.php`,
    autoships: () => `/autoships`,
    autoship: (id?: number) => `/autoships/${id ? `?id=${id}` : ''}`,
    orders: () => `/account.php?action=order_status`,
    payments: () => `/account.php?action=payment_methods`,
    settings: () => `/account.php?action=account_details`,
    addresses: {
      index: () => `/account.php?action=address_book`,
    },
    signout: () => `/login.php?action=logout`,
    order: (orderId: number | string) => `/account.php?action=view_order&order_id=${orderId}`,
  },
  login: () => `/login`,
  loginRedirect: (redirect: string) => `/login/?redirect_source=${redirect}`,
  createAccount: () => `/create-account`,
  account: {
    index: () => `/account/`,
    autoships: () => `/account/autoships/`,
    autoship: (id: string) => `/account/autoships/autoship/?id=${id}`,
    buyAgain: (id?: number) => `/account/buy-again/${id ? `?orderId=${id}` : ''}`,
    delay: (token?: string) => `/account/autoships/delay/${token ? `?token=${token}` : ''}`,
    orders: () => `/account/orders/`,
    order: (id: number) => `/account/orders/order/?orderId=${id}`,
    menu: () => `/account/menu`,
    payments: {
      index: () => `/account/payments/`,
      add: () => `/account/payments/add/`,
      edit: (token: string) => `/account/payments/edit/?token=${token}`,
    },
    addPaymentMethod: () => `/account/payments/add/`,
    quickManage: (token?: string) =>
      `/account/autoships/quick-manage/${token ? `?token=${token}` : ''}`,
    shipNow: (token?: string) => `/account/autoships/ship-now/${token ? `?token=${token}` : ''}`,
    settings: () => `/account/settings/`,
    addresses: {
      index: () => `/account/addresses/`,
      add: () => `/account/addresses/add/`,
      edit: (addressId: number) => `/account/addresses/edit/?addressId=${addressId}`,
    },
    orderCancellationConfirmation: (orderId: number) =>
      `/cancellation-confirmation/?order_id=${orderId}`,
  },
  ambassadorProgram: () => `/ambassador-program/`,
  autoship: () => `/autoship/`,
  blog: () => `/resources/blog/`,
  bulkOrders: () => `/bulk-orders/`,
  cart: () => `/cart/`,
  catalog: (path = '/') => `/catalog${path}`,
  category: (categories: string[] | string) => {
    const subPath = Array.isArray(categories) ? categories.join('/') : categories

    return `/category/${subPath.replace(/^(\/)+/, '')}`
  },
  checkout: () => `/checkout`,
  brand: (brandSlug: string) => `/brands/${brandSlug}`,
  featuredBrands: () => `/featured-brands`,
  faq: () => `/faq/`,
  deals: () => `/deals/`,
  resources: () => `/resources/blog/`,
  tagLandingPage: (slug: string) => `/resources/tags/${slug}`,
  careers: () => `/careers/`,
  newsroom: () => `/newsroom/`,
  medicare: () => `/insurance/`,
  ourStory: () => `/our-story/`,
  // TODO: refactor to support string _or_ URLObject
  search: (query?: string) => `/search/${query ? `?search_query_adv=${query}` : ''}`,
  privacyPolicy: () => `/privacy-policy/`,
  ccpa: () => `/ccpa/`,
  orderLookup: () => `/order-lookup/`,
  returns: () => `/returns/`,
  sitemap: () => `/sitemap/`,
  termsAndConditions: () => `/terms-conditions/`,
  webAccessibilityStatement: () => `/web-accessibility-statement/`,
  bigCommerceStorefrontApi: {
    passwordlessLogin: () => '/login.php?action=passwordless_login',
    setXSRFCookie: () => `/login.php`,
    cart: () =>
      `/api/storefront/carts?include=lineItems.physicalItems.options,lineItems.digitalItems.options`,
    addLineItem: (cartId: number | string) => `/api/storefront/carts/${cartId}/items`,
    deleteLineItem: (cartId: number | string, itemId: number | string) =>
      `/api/storefront/carts/${cartId}/items/${itemId}`,
  },
  cwAPI: {
    account: {
      address: {
        verify: () => `/cw-api/account/address/verify`,
      },
    },
    autoship: {
      guests: {
        putAutoshipCoupons: (cartId: string) =>
          `/cw-api/autoship/guests/carts/${cartId}/apply-coupons`,
      },
      orders: {
        cancel: (orderId: number) => `/cw-api/autoship/orders/${orderId}`,
      },
      customers: {
        // storefront cart only
        putAutoshipCoupons: (cartId: string) =>
          `/cw-api/autoship/customers/carts/${cartId}/apply-coupons`,
        // update customer segement
        updateAutoshipCustomerSegment: () => '/cw-api/autoship/customers/segment-generation',
        getPlansByAddress: (addressId: number) =>
          `/cw-api/autoship/customers/addresses/${addressId}/plans`,
      },
      plans: () => `/cw-api/autoship/plans`,
    },
    quicklink: {
      autoship: () => `/cw-api/quicklink/autoship/`,
      // emails a customer a quick link to automatically log in
      create: (email: string, redirect: string) =>
        `/cw-api/quicklink/create?email=${email}&redirect=${redirect}`,
    },
  },
  api: {
    account: {
      overview: {
        orders: () => `/api/account/overview/orders/`,
      },
    },
    lookupOrder: (orderId: number | string) => `/api/account/orders/${orderId}/lookup/`,
    // product
    getProduct: ({ id }: { id: string }) => `/api/product/${id}/`,
    getProductCombined: ({ id }: { id: string }) => `/api/product/combined/${id}/`,

    // cart
    addToCart: () => '/api/cart/item/add/',
    getCart: () => '/api/cart/get/',
    deleteCartItem: () => '/api/cart/item/delete/',
    updateCartItemQuantity: () => '/api/cart/item/updateQuantity/',
    updateCartItemVariant: () => '/api/cart/item/updateVariant/',
    syncCart: (cartId: string) =>
      `/api/storefront/checkouts/${cartId}/billing-address?include=cart.lineItems.physicalItems.options,cart.lineItems.digitalItems.options,customer,promotions.banners`,

    // marketing
    updateMarketingEmailSubscription: () => `/api/storefront/subscriptions`,

    // customer
    getCurrentCustomer: () => '/api/account/currentCustomer/',
    updateCurrentCustomer: () => '/api/account/update/',
    addresses: () => `/api/account/addresses`,
    address: (id: number) => `/api/account/addresses/${id}`,
    paymentMethods: () => `/api/account/paymentMethods`,
    paymentMethod: (id: number) => `/api/account/paymentMethod/${id}`,

    // checkout
    generateCheckoutRedirectUrl: () => '/api/checkout/generateRedirectUrl/',

    // session
    sessionState: () => '/api/session/sessionState/',

    // jwt
    jwtVerify: (token: string) => `/api/jwtVerify/?token=${token}`,

    // autoship
    orderHistory: () => '/autoship/orders/history',
    planDetails: (uuid: string) => `/autoship/plans/${uuid}/details`,
    resources: {
      auth: () => '/api/resources/auth',
    },
  },
  v2: {
    product: (productId: number | string) => `/api/v2/product/${productId}`,
  },
}

export default routes
