export function getInternalUserValue() {
  if (document.location.search.indexOf('x-carewell-experience') > 0) {
    window.localStorage.setItem('carewellEmployee', 'true')
  }
  const internalRedirectFromBCAdminPanel =
    document.location.pathname === '/account.php' &&
    document.referrer &&
    document.referrer.includes('.mybigcommerce.com/')
  const otherEmployeeLogic = window.localStorage.getItem('carewellEmployee') === 'true'

  return internalRedirectFromBCAdminPanel || otherEmployeeLogic ? 'yes' : 'no'
}
