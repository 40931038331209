import { useEffect, useMemo, useRef } from 'react'

let nProgress
let n = 0

async function initNProgress() {
  if (!nProgress && process.browser) {
    nProgress = (await import('nprogress')).default
  }
  return nProgress
}

export async function getProgressIndicator() {
  const progress = await initNProgress()

  if (progress) {
    return {
      inc: () => {
        n++
        progress.start()
      },
      dec: () => {
        n = Math.max(0, n - 1)
        if (!n) {
          progress.done()
        }
      },
    }
  }

  return {
    inc: () => {
      /* nothing */
    },
    dec: () => {
      /* nothing */
    },
  }
}

export function useShowNProgress(show: boolean) {
  const nprogress = useNProgress()

  useEffect(() => {
    if (show) {
      nprogress.inc()
    } else {
      nprogress.dec()
    }
  }, [show, nprogress])
}

export function useNProgress() {
  const indicatorRef = useRef<PromiseReturnType<typeof getProgressIndicator>>()

  useEffect(() => {
    ;(async function get() {
      const indicator = await getProgressIndicator()
      indicatorRef.current = indicator
    })()
  }, [])

  return useMemo(
    () => ({
      inc: () => {
        const indicator = indicatorRef.current

        if (process.browser && indicator) {
          indicator.inc()
        }
      },
      dec: () => {
        const indicator = indicatorRef.current

        if (process.browser && indicator) {
          indicator.dec()
        }
      },
    }),
    []
  )
}
