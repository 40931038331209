import React, { useCallback } from 'react'
import type { Recommendations } from './types'

export interface NostoState {
  nostoLoaded: boolean
  recommendationsLoading: boolean
  recommendationsLoaded: boolean
  recommendations: Recommendations[] | null
  pageType?: string
  productID: string | null
  category: string | null
  searchTerm: string | null
}

const initialState: NostoState = {
  nostoLoaded: false,
  recommendationsLoading: false,
  recommendationsLoaded: false,
  recommendations: null,
  pageType: undefined,
  productID: null,
  category: null,
  searchTerm: null,
}

type NostoAction =
  | {
      type: 'LOAD_NOSTO_SCRIPT'
    }
  | {
      type: 'SET_LOADING_NOSTO_RECOMMENDATIONS'
      recommendationsLoading: boolean
    }
  | {
      type: 'LOAD_NOSTO_RECOMMENDATIONS'
      recommendations: Recommendations[] | null
    }
  | {
      type: 'SET_NOSTO_PAGE'
      pageType: string
    }
  | {
      type: 'SET_NOSTO_PRODUCT'
      productID: string
    }
  | {
      type: 'SET_NOSTO_CATEGORY'
      category: string
    }
  | {
      type: 'SET_NOSTO_SEARCH'
      searchTerm: string
    }

function NostoReducer(state: NostoState, action: NostoAction): NostoState {
  switch (action.type) {
    case 'LOAD_NOSTO_SCRIPT':
      return {
        ...state,
        nostoLoaded: true,
      }
    case 'SET_LOADING_NOSTO_RECOMMENDATIONS':
      return {
        ...state,
        recommendationsLoading: action.recommendationsLoading,
      }
    case 'LOAD_NOSTO_RECOMMENDATIONS':
      return {
        ...state,
        recommendationsLoaded: !!action.recommendations,
        recommendations: action.recommendations,
      }
    case 'SET_NOSTO_PAGE':
      return {
        ...state,
        pageType: action.pageType,
      }
    case 'SET_NOSTO_PRODUCT':
      return {
        ...state,
        productID: action.productID,
      }
    case 'SET_NOSTO_CATEGORY':
      return {
        ...state,
        category: action.category,
      }
    case 'SET_NOSTO_SEARCH':
      return {
        ...state,
        searchTerm: action.searchTerm,
      }
    default:
      return state
  }
}

function useNostoReducer() {
  const [state, dispatch] = React.useReducer(NostoReducer, initialState)
  const loadNosto = useCallback(() => dispatch({ type: 'LOAD_NOSTO_SCRIPT' }), [])
  const loadingNostoRecommendations = useCallback(
    (recommendationsLoading: boolean) =>
      dispatch({ type: 'SET_LOADING_NOSTO_RECOMMENDATIONS', recommendationsLoading }),
    []
  )
  const loadRecs = useCallback(
    (recommendations: Recommendations[] | null) =>
      dispatch({ type: 'LOAD_NOSTO_RECOMMENDATIONS', recommendations }),
    []
  )
  const setPageType = useCallback((pageType) => dispatch({ type: 'SET_NOSTO_PAGE', pageType }), [])
  const setProductID = useCallback(
    (productID) => dispatch({ type: 'SET_NOSTO_PRODUCT', productID }),
    []
  )
  const setCategory = useCallback(
    (category) => dispatch({ type: 'SET_NOSTO_CATEGORY', category }),
    []
  )
  const setSearchTerm = useCallback(
    (searchTerm) => dispatch({ type: 'SET_NOSTO_SEARCH', searchTerm }),
    []
  )
  return {
    ...state,
    loadNosto,
    loadingNostoRecommendations,
    loadRecs,
    setPageType,
    setProductID,
    setCategory,
    setSearchTerm,
  }
}

export const NostoContext = React.createContext<ReturnType<typeof useNostoReducer>>(
  // Initialised in the provider
  null as never
)

export function NostoContextProvider({ children }: { children: React.ReactNode }) {
  const value = useNostoReducer()
  return <NostoContext.Provider value={value}>{children}</NostoContext.Provider>
}

export function useNostoContext() {
  return React.useContext(NostoContext)
}
