import { httpBatchLink, httpLink, splitLink } from '@trpc/client'
import { createTRPCNext } from '@trpc/next'
import { trpcFetcher } from '#utils/trpc/trpc-fetcher'
import superjson from 'superjson'
import type { AppRouter } from '../../server/routers/_app'

function getBaseUrl() {
  if (typeof window !== 'undefined')
    // browser should use relative path
    return ''
  if (process.env.VERCEL_URL)
    // reference for vercel.com
    return `https://${process.env.VERCEL_URL}`
  return `http://localhost:${process.env.PORT ?? 3000}`
}

export const trpc = createTRPCNext<AppRouter>({
  config() {
    return {
      transformer: superjson,
      links: [
        splitLink({
          condition(op) {
            return op.context.skipBatch === true
          },
          false: httpBatchLink({
            url: `${getBaseUrl()}/api/trpc`,
            fetch: trpcFetcher,
          }),
          true: httpLink({
            url: `${getBaseUrl()}/api/trpc`,
            fetch: trpcFetcher,
          }),
        }),
      ],
    }
  },
  /**
   * @link https://trpc.io/docs/ssr
   **/
  ssr: false,
})
