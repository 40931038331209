import { useCallback } from 'react'
import { InferProcedureOutput, Procedures } from 'server/routers/_app'
import useDataLayerPush from './useDataLayerPush'

type PageType =
  | 'homepage'
  | 'brands'
  | 'deals'
  | 'pdp'
  | 'plp'
  | 'search_results'
  | 'resources'
  | 'cart'
  | 'checkout'
  | 'purchase'

export const getPageType = (pathname: string): PageType | undefined => {
  if (pathname === '/') {
    return 'homepage'
  }
  if (pathname.startsWith('/featured-brands')) {
    return 'brands'
  }
  if (pathname.startsWith('/deals')) {
    return 'deals'
  }
  if (pathname.startsWith('/product/')) {
    return 'pdp'
  }
  if (pathname.startsWith('/search/')) {
    return 'search_results'
  }
  if (pathname.startsWith('/resources')) {
    return 'resources'
  }
  if (pathname.startsWith('/cart')) {
    return 'cart'
  }
  if (pathname.startsWith('/checkout')) {
    return 'checkout'
  }
  if (pathname.startsWith('/purchase')) {
    return 'purchase'
  }
  if (pathname.startsWith('/catalog') || pathname.startsWith('/brands')) {
    return 'plp'
  }
}

export function useGTMPageViewEvent() {
  const push = useDataLayerPush()

  return useCallback(
    ({ customer }: { customer?: InferProcedureOutput<Procedures['customer']['getCustomer']> }) => {
      window.carewell = {
        sessionState: {},
        asyncScripts: {
          nostojs: {
            loaded: false,
            loading: false,
          },
          googleMaps: {
            loaded: false,
            loading: false,
          },
        },
      }
      push({
        mainData: {
          page_title: document.title,
          page_type: getPageType(window.document.location.pathname),
          user_status: customer ? 'logged_in' : 'logged_out',
        },
        event: { name: 'pageview' },
        customer,
      })
    },
    [push]
  )
}
