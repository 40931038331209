import React, { useEffect, useState } from 'react'
import { updateCustomerSegments } from '../../lib/clientOnly/api/updateCustomerSegments'
import routes from '../../lib/routes'

export type Customer = {
  entityId: number
  firstName: string
  lastName: string
  email: string
  company: string
  customerGroupId: number
  notes: string
  phone: string
  addressCount: number
  attributeCount: number
  storeCredit: Array<{ value: number; currencyCode: string }>
} | null

export type ServerState = {
  customer: Customer
  cart: {
    base_amount: number
    cart_amount: number
    channel_id: number
    coupons: Array<unknown>
    created_time: string
    currency: { code: 'USD' }
    customer_id: number
    discount_amount: number
    email: string
    id: string
    line_items: Array<{
      physical_items: Array<{
        coupon_amount: number
        coupons: Array<unknown>
        discount_amount: number
        discounts: Array<unknown>
        extended_list_price: number
        extended_sale_price: number
        id: string
        image_url: string
        list_price: number
        name: string
        options: Array<{
          name: string
          nameId: number
          value: string
          valueId: number
        }>
        product_id: number
        quantity: number
        sale_price: number
        sku: string
        taxable: boolean
        url: string
      }>
      digital_items: []
      gift_certificates: []
      custom_items: []
    }>
    locale: 'en'
    tax_included: boolean
  } | null
}

// TODO: Remove this once we are off big commerce checkout and re-think how to track employees
function legacySetEmployeeFlag() {
  window?.localStorage?.setItem('carewellEmployee', 'true')
}

// semi-private object keys
const __stateKey__ = Symbol()
const __listeners__ = Symbol()
const __fireEvents__ = Symbol()

// client-side sessionState store
const SessionStateStore = {
  // bind to session state changes
  listenForChange: (callback) => {
    const key = Symbol()
    SessionStateStore[__listeners__][key] = callback
    return function unbind() {
      delete SessionStateStore[__listeners__][key]
    }
  },
  // update session data method
  updateData: (nextSessionState) => {
    SessionStateStore[__stateKey__] = {
      ...SessionStateStore[__stateKey__],
      ...nextSessionState,
    }
    SessionStateStore[__fireEvents__]()
  },
  getState: () => ({ ...SessionStateStore[__stateKey__] }),
  refreshFromServer: async () => {
    const resp = await fetch(routes.api.sessionState())
    const json = await resp.json()

    const serverState =
      json && json.valid && json.serverState ? (json.serverState as ServerState) : undefined

    if (serverState) {
      if (serverState?.customer?.email?.includes('@carewell.com')) {
        legacySetEmployeeFlag()
      }
      SessionStateStore.updateData(json.serverState)
      if (serverState.customer) {
        // make sure the user is logged in, otherwise this call will fail
        await updateCustomerSegments()
      }
    }

    return { ...SessionStateStore[__stateKey__] }
  },
  // private: default session state
  [__stateKey__]: {
    cart: null,
    customer: null,
  } as ServerState,
  // private: listeners callback queue
  [__listeners__]: {},
  // private: fire event queue
  [__fireEvents__]: () => {
    Reflect.ownKeys(SessionStateStore[__listeners__]).forEach((key) =>
      SessionStateStore[__listeners__][key]({ ...SessionStateStore[__stateKey__] })
    )
  },
  initiated: false,
}

type SessionState = ReturnType<typeof SessionStateStore.getState>
export const SessionContext = React.createContext<{
  sessionState: SessionState
  loading: boolean
  refreshFromServer: () => Promise<SessionState>
  setSessionState: (state: SessionState) => void
}>(null as never)

export default function SessionStateProvider({ children }: { children: React.ReactNode }) {
  const [componentSessionState, setComponentSessionState] = useState(SessionStateStore.getState())
  const [loading, setLoading] = useState(!SessionStateStore.initiated)

  useEffect(() => {
    const unbind = SessionStateStore.listenForChange((nextSessionState) => {
      setComponentSessionState({ ...nextSessionState })
    })

    return function cleanup() {
      unbind()
    }
  }, [])

  useEffect(() => {
    if (!SessionStateStore.initiated) {
      SessionStateStore.initiated = true
      setLoading(true)
      SessionStateStore.refreshFromServer().finally(() => {
        setLoading(false)
      })
    }
  }, [])

  const refreshFromServer = async () => {
    setLoading(true)
    const state = await SessionStateStore.refreshFromServer()
    setLoading(false)
    return state
  }

  useEffect(() => {
    const listener = () => {
      refreshFromServer().catch((err) => console.error(err))
    }
    addEventListener('focus', listener)

    return () => removeEventListener('focus', listener)
  }, [])

  return (
    <SessionContext.Provider
      value={{
        sessionState: componentSessionState,
        loading,
        setSessionState: (next) => {
          SessionStateStore.updateData(next)
        },
        refreshFromServer,
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}
