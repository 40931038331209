import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { getCookie, setCookie, setSessionCookie } from '../../../utils/cookies'

/**
 * As per https://searchspring.zendesk.com/hc/en-us/articles/115000446623-Search-API-Request
 */
export function getSearchSpringSessionId() {
  const sessionId = getCookie('ssSessionIdNamespace')

  if (!sessionId) {
    const newSessionId = uuidv4()
    setSessionCookie('ssSessionIdNamespace', newSessionId)
    return newSessionId
  }

  return sessionId
}

/**
 * As per https://searchspring.zendesk.com/hc/en-us/articles/115000446623-Search-API-Request
 */
export function getSearchSpringUserId(customerId?: number | null) {
  const userId = getCookie('ssUserId')

  if (!userId) {
    const newUserId = customerId?.toString() || uuidv4()
    const expiryDate = new Date()
    expiryDate.setFullYear(expiryDate.getFullYear() + 1)
    setCookie('ssUserId', newUserId, expiryDate)
    setCookie('_isuid', newUserId, expiryDate)
    return newUserId
  }

  return userId
}

let pageLoadId = uuidv4()

export function useMaintainSearchSpringPageLoadId() {
  const router = useRouter()

  useEffect(() => {
    const handler = () => {
      pageLoadId = uuidv4()
    }

    router.events.on('routeChangeComplete', handler)

    return () => router.events.off('routeChangeComplete', handler)
  }, [router])
}

export function getSearchSpringPageLoadId() {
  if (!pageLoadId) {
    const nextPageLoadId = uuidv4()
    pageLoadId = nextPageLoadId
    return nextPageLoadId
  }

  return pageLoadId
}
