// fetcher.ts

import type { RequestInitEsque } from '@trpc/client/dist/internals/types'

export const trpcFetcher = async (
  info: RequestInfo | URL,
  options: RequestInit | RequestInitEsque | undefined
) => {
  const originalResponse = await fetch(info, options)

  if (originalResponse.status === 401) {
    // Let's try to refresh the access token
    const res = await fetch('/api/refresh')

    // We were unable to refresh the access token
    if (!res.ok) return originalResponse

    // We were able to refresh the access token - let's try again!
    return await fetch(info, options)
  }

  return originalResponse
}
