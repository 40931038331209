import Head from 'next/head'
import { useRouter } from 'next/router'
import 'nprogress/nprogress.css'
import { useEffect } from 'react'
import { KustomerChatProvider } from '../components/global/kustomer-chat-context'
import { NostoContextProvider } from '../components/global/nosto/NostoContext'
import { NostoScript } from '../components/global/nosto/NostoScript'
import SessionStateProvider from '../components/global/session-context'
import { UIProvider } from '../components/global/ui-context'
import { useNProgress } from '../lib/clientOnly/nprogress'
import '../styles/globals.css'
import useGTMPageViewEffect from 'lib/clientOnly/analytics/gtm/useGTMPageViewEffect'
import { Inter } from 'next/font/google'
import useDataLayerPush from '../lib/clientOnly/analytics/gtm/useDataLayerPush'
import { useMaintainSearchSpringPageLoadId } from '../lib/clientOnly/analytics/searchspring/tracking'
import { publicConfig } from '../lib/publicConfig'
import { trpc } from '../lib/utils/trpc'

const inter = Inter({
  subsets: ['latin'],
  variable: '--font-inter',
})

/**
 * This is needed to make legacy work with the next-generated font names for
 * Tailwind.
 *
 * Also note that this font is also imported and used in _document.tsx. THIS IS
 * BY DESIGN. Explanation: fonts need to be imported in _app.tsx to actually be
 * processed, but the html tag needs to end up in _document.tsx for some reason.
 * Hence we have to import it in two places for it to work.
 */
export const fontCss = `
:root {
  --font-circular-std: ${inter.variable};
  --font-inter: ${inter.variable};
}
`

function useInitLogging() {
  useEffect(() => {
    if (process.browser) {
      const loggingEnabled =
        localStorage.getItem('__carewellLogging') === 'true' ?? publicConfig.loggingEnabled
      window.__carewellLogging = loggingEnabled
      localStorage.setItem('__carewellLogging', loggingEnabled ? 'true' : 'false')
    }
  }, [])
}

function useRouterLoader() {
  const router = useRouter()
  const nprogress = useNProgress()

  useEffect(() => {
    const onRouteChangeStart = () => {
      nprogress.inc()
    }
    const onRouteChangeEnd = () => {
      nprogress.dec()
    }
    router.events.on('routeChangeStart', onRouteChangeStart)
    router.events.on('routeChangeComplete', onRouteChangeEnd)
    router.events.on('routeChangeError', onRouteChangeEnd)

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
      router.events.off('routeChangeComplete', onRouteChangeEnd)
      router.events.off('routeChangeError', onRouteChangeEnd)
    }
  }, [nprogress, router])
}

function MyApp({ Component, pageProps }) {
  const push = useDataLayerPush()
  useInitLogging()
  useGTMPageViewEffect()
  useRouterLoader()
  useMaintainSearchSpringPageLoadId()
  push({ mainData: { pageType: pageProps?.content?.name, ...pageProps } })

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <style dangerouslySetInnerHTML={{ __html: fontCss }} />
      </Head>
      <SessionStateProvider>
        <UIProvider>
          <NostoContextProvider>
            <KustomerChatProvider>
              <NostoScript />
              <div id="modal" />
              <Component {...pageProps} />
            </KustomerChatProvider>
          </NostoContextProvider>
        </UIProvider>
      </SessionStateProvider>
    </>
  )
}

export default trpc.withTRPC(MyApp)
