import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import useCustomerQuery from '../../../../hooks/useCustomerQuery'
import { useGTMPageViewEvent } from './useGTMPageViewEvent'

export default function useGTMPageViewEffect() {
  const pushGtm = useGTMPageViewEvent()

  const [customerAvailable, setCustomerAvailable] = useState(false)
  const router = useRouter()

  const { data: customer, isLoading } = useCustomerQuery()

  // Customer data is available when query is finished loading
  useEffect(() => {
    setCustomerAvailable(!isLoading)
  }, [isLoading])

  // Wait for customer data to be available before sending page view event
  useEffect(() => {
    if (customerAvailable) pushGtm({ customer })
  }, [router.asPath, customerAvailable])
}
