/* eslint-disable @typescript-eslint/no-empty-function */
import { useLayoutEffect } from 'react'

/**
 * This hook is a simple wrapper around useLayoutEffect to enforce client-side use only.
 *
 * Docs:
 *   - useLayoutEffect is for mutating the DOM and/or performing measurements
 *   - useEffect is for everything else
 *
 * Background:
 *   When useLayoutEffect is used in a Server-Side Rendered component, React throws a warning
 *   that there is no effect on the server and it will lead to mismatch in UI state.
 *
 * See https://reactjs.org/link/uselayouteffect-ssr
 *
 */
export const useBrowserLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : () => {}
