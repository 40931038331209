import { trpc } from '../lib/utils/trpc'

export default function useCustomerQuery() {
  return trpc.customer.getCustomer.useQuery(undefined, {
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    keepPreviousData: true,
    trpc: {
      context: {
        // Show auth state ASAP - avoid waiting for other, potentially slow queries, to finish
        skipBatch: true,
      },
    },
  })
}

export type CustomerQueryData = NonNullable<ReturnType<typeof useCustomerQuery>['data']>
