import Router, { useRouter } from 'next/router'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useBrowserLayoutEffect } from '../../../hooks/useBrowserLayoutEffect'
import routes from '../../../lib/routes'

export interface State {
  displayMiniCart: boolean
  displayAuthModal: boolean
  displayAuthModalRedirectToCheckout?: boolean
  displayRedirectCheckoutModal: boolean
  displayAutoshipModal: boolean
  displayHolidayShippingModal: boolean
  displaySearchSuggestions: boolean
  backgroundScrollLockCounter: number
  redirectToPage?: string
  redirectToPageURL?: string
}

const initialState: State = {
  displayMiniCart: false,
  displayAuthModal: false,
  displayRedirectCheckoutModal: false,
  displayAutoshipModal: false,
  displayHolidayShippingModal: false,
  displaySearchSuggestions: false,
  redirectToPage: '',
  redirectToPageURL: '',
  // Manage the number of "locks" e.g. if we have layered models (or attentive opens) to avoid removing scroll lock
  // prior to all overlays being closed
  backgroundScrollLockCounter: 0,
}

type Action =
  | {
      type: 'OPEN_MINICART'
    }
  | {
      type: 'CLOSE_MINICART'
    }
  | {
      type: 'TOGGLE_MINICART'
    }
  | {
      type: 'OPEN_AUTH_MODAL'
      redirectToCheckout?: State['displayAuthModalRedirectToCheckout']
      redirectToPage?: string
      redirectToPageURL?: string
    }
  | {
      type: 'CLOSE_AUTH_MODAL'
    }
  | {
      type: 'OPEN_SEARCH_MENU'
    }
  | {
      type: 'CLOSE_SEARCH_MENU'
    }
  | {
      type: 'OPEN_SEARCH_SUGGESTIONS'
    }
  | {
      type: 'CLOSE_SEARCH_SUGGESTIONS'
    }
  | {
      type: 'TOGGLE_SEARCH_SUGGESTIONS'
    }
  | {
      type: 'REDIRECT_TO_CHECKOUT'
    }
  | {
      type: 'OPEN_AUTOSHIP_MODAL'
    }
  | {
      type: 'CLOSE_AUTOSHIP_MODAL'
    }
  | {
      type: 'TOGGLE_SEARCH_MENU'
    }
  | {
      type: 'LOCK_BACKGROUND_SCROLL'
    }
  | {
      type: 'UNLOCK_BACKGROUND_SCROLL'
    }
  | {
      type: 'OPEN_HOLIDAY_SHIPPING_MODAL'
    }
  | {
      type: 'CLOSE_HOLIDAY_SHIPPING_MODAL'
    }

function uiReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'OPEN_MINICART':
      return {
        ...state,
        displayMiniCart: true,
      }
    case 'CLOSE_MINICART':
      return {
        ...state,
        displayMiniCart: false,
      }
    case 'TOGGLE_MINICART':
      return {
        ...state,
        displayMiniCart: !state.displayMiniCart,
      }
    case 'OPEN_AUTH_MODAL':
      return {
        ...state,
        displayAuthModal: true,
        displayAuthModalRedirectToCheckout: action?.redirectToCheckout,
        redirectToPage: action?.redirectToPage,
        redirectToPageURL: action?.redirectToPageURL,
      }
    case 'CLOSE_AUTH_MODAL':
      return {
        ...state,
        displayAuthModal: false,
        redirectToPage: '',
        redirectToPageURL: '',
        displayAuthModalRedirectToCheckout: false,
      }
    case 'REDIRECT_TO_CHECKOUT':
      return {
        ...state,
        displayRedirectCheckoutModal: true,
      }
    case 'OPEN_AUTOSHIP_MODAL':
      return {
        ...state,
        displayAutoshipModal: true,
      }
    case 'CLOSE_AUTOSHIP_MODAL':
      return {
        ...state,
        displayAutoshipModal: false,
      }
    case 'TOGGLE_SEARCH_SUGGESTIONS':
      return { ...state, displaySearchSuggestions: !state.displaySearchSuggestions }
    case 'OPEN_SEARCH_SUGGESTIONS':
      return { ...state, displaySearchSuggestions: true }
    case 'CLOSE_SEARCH_SUGGESTIONS':
      return { ...state, displaySearchSuggestions: false }
    case 'LOCK_BACKGROUND_SCROLL':
      return { ...state, backgroundScrollLockCounter: state.backgroundScrollLockCounter + 1 }
    case 'UNLOCK_BACKGROUND_SCROLL':
      return {
        ...state,
        backgroundScrollLockCounter: Math.max(0, state.backgroundScrollLockCounter - 1),
      }
    case 'OPEN_HOLIDAY_SHIPPING_MODAL':
      return {
        ...state,
        displayHolidayShippingModal: true,
      }
    case 'CLOSE_HOLIDAY_SHIPPING_MODAL':
      return {
        ...state,
        displayHolidayShippingModal: false,
      }
    default:
      return state
  }
}

function lockBodyScroll(scrollY: number) {
  document.body.style.position = 'fixed'
  document.body.style.top = `-${scrollY}px`
  document.body.style.width = '100%'
  document.body.style.height = '100%'
}

function unlockBodyScroll() {
  document.body.style.position = ''
  document.body.style.top = ''
  document.body.style.width = ''
  document.body.style.height = ''
}

/**
 * Prevents 3rd party modals removing background scroll lock
 */
function useRetainScrollLock({
  backgroundScrollY,
  backgroundScrollIsLocked,
}: {
  backgroundScrollY: number
  backgroundScrollIsLocked: boolean
}) {
  const observerRef = useRef<MutationObserver | null>(null)

  useBrowserLayoutEffect(() => {
    if (!observerRef.current && backgroundScrollIsLocked) {
      // listens for changes to the body attributes when the attentive modal spawns
      // keeps the body style fixed if another modal is still open beneath the attentive modal (a 3rd party script which clears body styles on close)
      observerRef.current = new MutationObserver(() => {
        lockBodyScroll(backgroundScrollY)
      })
      observerRef.current.observe(document.body, { attributes: true, attributeFilter: ['style'] })
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
        observerRef.current = null
      }
    }
  }, [backgroundScrollIsLocked, backgroundScrollY])
}

function useUIReducer() {
  const router = useRouter()
  const authModalDisabled =
    router.pathname.startsWith(routes.createAccount()) || router.pathname.startsWith(routes.login())

  const [{ backgroundScrollLockCounter, ...restOfState }, dispatch] = React.useReducer(
    uiReducer,
    initialState
  )
  const openMinicart = useCallback(() => dispatch({ type: 'OPEN_MINICART' }), [])
  const closeMinicart = useCallback(() => dispatch({ type: 'CLOSE_MINICART' }), [])
  const toggleMinicart = useCallback(() => dispatch({ type: 'TOGGLE_MINICART' }), [])
  const openCheckoutRedirectModal = useCallback(
    () => dispatch({ type: 'REDIRECT_TO_CHECKOUT' }),
    []
  )

  const openAuthModal = useCallback(
    ({
      redirectToCheckout,
      redirectToPage,
      redirectToPageURL,
    }: {
      redirectToCheckout?: State['displayAuthModalRedirectToCheckout']
      redirectToPage?: string
      redirectToPageURL?: string
    } = {}) => {
      const authModalEnabled = !authModalDisabled

      if (authModalEnabled) {
        dispatch({
          type: 'OPEN_AUTH_MODAL',
          redirectToCheckout,
          redirectToPage,
          redirectToPageURL,
        })
      }
    },
    [authModalDisabled]
  )
  const closeAuthModal = useCallback(() => dispatch({ type: 'CLOSE_AUTH_MODAL' }), [])
  const openSearchMenu = useCallback(() => dispatch({ type: 'OPEN_SEARCH_MENU' }), [])
  const toggleSearchMenu = useCallback(() => dispatch({ type: 'TOGGLE_SEARCH_MENU' }), [])
  const closeSearchMenu = useCallback(() => dispatch({ type: 'CLOSE_SEARCH_MENU' }), [])

  const openAutoshipModal = useCallback(() => dispatch({ type: 'OPEN_AUTOSHIP_MODAL' }), [])
  const closeAutoshipModal = useCallback(() => dispatch({ type: 'CLOSE_AUTOSHIP_MODAL' }), [])

  const toggleSearchSuggestions = useCallback(
    () => dispatch({ type: 'TOGGLE_SEARCH_SUGGESTIONS' }),
    []
  )
  const closeSearchSuggestions = useCallback(
    () => dispatch({ type: 'CLOSE_SEARCH_SUGGESTIONS' }),
    []
  )
  const openSearchSuggestions = useCallback(() => dispatch({ type: 'OPEN_SEARCH_SUGGESTIONS' }), [])

  const lockBackgroundScroll = useCallback(() => dispatch({ type: 'LOCK_BACKGROUND_SCROLL' }), [])
  const unlockBackgroundScroll = useCallback(
    () => dispatch({ type: 'UNLOCK_BACKGROUND_SCROLL' }),
    []
  )

  useEffect(() => {
    const handleRouteChange = () => {
      closeMinicart()
      // Do not close auth modal for checkout page transfer
      // closeAuthModal()
    }

    Router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      Router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [closeMinicart, closeAuthModal])

  const backgroundScrollIsLocked = backgroundScrollLockCounter > 0
  const [backgroundScrollY, setBackgroundScrollY] = useState(0)

  useBrowserLayoutEffect(() => {
    const lock = () => {
      const scrollY = window.scrollY
      setBackgroundScrollY(scrollY)
      lockBodyScroll(scrollY)
    }

    const unlock = () => {
      const scrollY = document.body.style.top
      if (scrollY) {
        unlockBodyScroll()
        setBackgroundScrollY(0)
        window.scrollTo(0, parseInt(scrollY || '0') * -1)
      }
    }

    if (backgroundScrollIsLocked) lock()
    else unlock()

    return unlock
  }, [backgroundScrollIsLocked])

  useRetainScrollLock({
    backgroundScrollY,
    backgroundScrollIsLocked,
  })

  const openHolidayShippingModal = useCallback(
    () => dispatch({ type: 'OPEN_HOLIDAY_SHIPPING_MODAL' }),
    []
  )

  const closeHolidayShippingModal = useCallback(
    () => dispatch({ type: 'CLOSE_HOLIDAY_SHIPPING_MODAL' }),
    []
  )

  return {
    backgroundScrollIsLocked,
    backgroundScrollY,
    ...restOfState,
    setBackgroundScrollY,
    openMinicart,
    closeMinicart,
    toggleMinicart,
    openAuthModal,
    closeAuthModal,
    openSearchMenu,
    toggleSearchMenu,
    closeSearchMenu,
    openCheckoutRedirectModal,
    openAutoshipModal,
    closeAutoshipModal,
    openHolidayShippingModal,
    closeHolidayShippingModal,
    openSearchSuggestions,
    closeSearchSuggestions,
    toggleSearchSuggestions,
    lockBackgroundScroll,
    unlockBackgroundScroll,
  }
}

export const UIContext = React.createContext<ReturnType<typeof useUIReducer>>(
  // Initialised in the provider
  null as never
)

export function UIProvider({ children }: { children: React.ReactNode }) {
  const value = useUIReducer()
  return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}

export function useUI() {
  return React.useContext(UIContext)
}
