/**
 * An object with all possible top level GTM dataLayer keys to reset between each call.
 * According to an industry expert, sending undefined is the best way.
 * I did not investigate other approaches.
 * https://www.simoahava.com/gtm-tips/remember-to-flush-unused-data-layer-variables/
 *
 */
export const mainReset = {
  customer: undefined,
  ecommerce: undefined,
  eventAction: undefined,
  event: undefined,
}

export const ecommerceReset = {
  detail: undefined,
  impressions: undefined,
  actionField: undefined,
  add: undefined,
  cart: undefined,
}
