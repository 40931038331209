export function getCookie(name: string): string | undefined {
  return (document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)') || []).pop()
}

export function setSessionCookie(name: string, value: string) {
  document.cookie = `${name}=${value}; path=/`
}

export function setCookie(name: string, value: string, expiry: Date) {
  document.cookie = `${name}=${value}; path=/; expires=${expiry.toUTCString()}`
}
