import { useCallback } from 'react'
import { getCustomerSegments } from '../../../utils/getCustomerSegments'
import { ecommerceReset, mainReset } from '../gtm/masterObjectReset'
import { getInternalUserValue } from '../utils/getInternalUserValue'
import type {InferProcedureOutput, Procedures} from "../../../../server/routers/_app";

export default function useDataLayerPush() {
  return useCallback(
    ({
      mainData = {},
      ecommerceData,
      event,
      customer = null,
    }: {
      mainData?: any
      ecommerceData?: any
      event?: {
        name: string
        action?: string
        category?: string
        label?: number | string
      }
      customer?: InferProcedureOutput<Procedures['customer']['getCustomer']>
    }) => {
      if (typeof window !== 'undefined') {
        window.dataLayer?.push({ ...mainReset })
        const {
          hasOrders,
          hasCancelledAutoship,
          hasMultipleOrders,
          hasMultipleActiveAutoships,
          hasActiveAutoships,
        } = getCustomerSegments(customer?.groupId ?? 0)

        const data = {
          ...mainReset,
          customer: customer
            ? {
                id: customer.id,
                email: customer.email,
                phone: customer.phone,
                first_name: customer.firstName,
                last_name: customer.lastName,
                city: customer.addresses[0]?.city,
                state: customer.addresses[0]?.stateOrProvince,
                country: customer.addresses[0]?.countryCode,
                zip: customer.addresses[0]?.postalCode,
                internalUser: getInternalUserValue() === 'yes',
                hasOrders,
                hasOrdersMulti: hasMultipleOrders,
                hasAutoshipActive: hasActiveAutoships,
                hasAutoshipCancelled: hasCancelledAutoship,
                hasAutoshipMulti: hasMultipleActiveAutoships,
              }
            : undefined,
          ecommerce: ecommerceData
            ? {
                ...ecommerceReset,
                ...ecommerceData,
              }
            : undefined,
          page: window.document.location.pathname,
          event: event ? event.name : undefined,
          event_category: event ? event?.category : undefined,
          event_action: event ? event?.action : undefined,
          event_label: event ? event?.label : undefined,
          ...mainData,
        }

        if (localStorage.getItem('gtm-debug') === 'true') {
          // eslint-disable-next-line no-console
          console.log('[GTM] push', data)
        }

        window.dataLayer?.push(data)
      }
    },
    []
  )
}
